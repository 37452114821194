document.addEventListener('DOMContentLoaded', () => {
  function registerFormFields() {
    const formFields = document.body.querySelectorAll('.x-form-field');

    formFields.forEach((ff) => {
      const input = ff.querySelector('input');
      if (!!input) {
        // input.addEventListener('focus', () => {
        //   ff.classList.add('touched');
        //   ff.classList.add('focused');
        // });
        // input.addEventListener('blur', () => {
        //   ff.classList.add('blurred');
        //   ff.classList.remove('focused');
        // });
        input.addEventListener('change', () => {
          ff.classList.add('dirty');
        });
      }

      const select = ff.querySelector('select');
      if (!!select) {
        // select.addEventListener('focus', () => {
        //   ff.classList.add('touched');
        //   ff.classList.add('focused');
        // });
        // select.addEventListener('blur', () => {
        //   ff.classList.add('blurred');
        //   ff.classList.remove('focused');
        // });
        select.addEventListener('change', () => {
          ff.classList.add('dirty');
        });
      }

      const textarea = ff.querySelector('textarea');
      if (!!textarea) {
        // textarea.addEventListener('focus', () => {
        //   ff.classList.add('touched');
        //   ff.classList.add('focused');
        // });
        // textarea.addEventListener('blur', () => {
        //   ff.classList.add('blurred');
        //   ff.classList.remove('focused');
        // });
        textarea.addEventListener('change', () => {
          ff.classList.add('dirty');
        });
      }
    });
  }

  registerFormFields();
  window.registerFormFields = registerFormFields;
});
